import React from "react";

const MovieDetail : React.FC = () => {
    return (
        <div>
            <h1>Movie Detail</h1>
        </div>
    )
}

export default MovieDetail;