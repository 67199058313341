import React from "react";

const TvPage : React.FC = () => {
    return (
        <div>
            <h1>Tv Page</h1>
        </div>
    )
}

export default TvPage;